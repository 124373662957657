export const Coin1 = ({ className, mainColor = '#BAFF62', sideColor = '#95CC4E' }) => (
  <svg
    className={className}
    width="170"
    height="186"
    viewBox="0 0 170 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="89.8651"
      cy="89.8327"
      rx="68.9864"
      ry="45.3673"
      transform="rotate(-120.061 89.8651 89.8327)"
      fill={sideColor}
      stroke="black"
    />
    <ellipse
      cx="79.3404"
      cy="95.9244"
      rx="68.9864"
      ry="45.3673"
      transform="rotate(-120.061 79.3404 95.9244)"
      fill={mainColor}
      stroke="black"
    />
    <path
      d="M63.6815 125.743L64.7664 118.276C62.2122 115.151 60.0266 111.762 58.2094 108.109C56.3923 104.455 54.9526 100.73 53.8901 96.9325C52.7739 93 52.0499 89.1457 51.718 85.3696C51.3983 81.5105 51.4915 77.8385 51.9978 74.3537C52.5643 70.454 53.5494 67.2082 54.9532 64.6163C56.3569 62.0244 58.0953 60.159 60.1684 59.02C62.1877 57.7461 64.4638 57.2297 66.9967 57.4708C69.5417 57.6289 72.2058 58.4821 74.989 60.0305L75.356 71.1404C73.6083 70.0391 71.8723 69.3631 70.1478 69.1125C68.3696 68.7269 66.7675 68.8965 65.3416 69.6212C63.8619 70.211 62.5912 71.3819 61.5296 73.1339C60.468 74.8859 59.7142 77.2969 59.2682 80.3669C58.6414 84.6815 58.9635 89.2822 60.2345 94.1692C61.5176 98.9732 63.5466 103.441 66.3214 107.572L68.9251 89.65L76.0342 95.2584L73.1051 115.421L76.6597 118.225L79.5888 98.0627L86.6979 103.671L84.0762 121.718C87.3693 122.281 90.0858 121.511 92.2257 119.407C94.3776 117.221 95.779 113.887 96.4299 109.407C96.876 106.337 96.8996 103.396 96.501 100.585C96.0486 97.6381 95.3445 94.9093 94.3887 92.3981C93.3791 89.7519 92.1507 87.3494 90.7035 85.1903C89.2683 82.9483 87.7191 80.9863 86.0558 79.3042L89.2435 70.9985C91.8459 73.7915 94.1785 76.9264 96.2413 80.4034C98.3162 83.7975 100.029 87.4142 101.378 91.2536C102.662 95.041 103.524 98.9577 103.963 103.004C104.403 107.05 104.327 111.105 103.737 115.171C103.23 118.656 102.38 121.731 101.186 124.395C100.004 126.977 98.5226 129.092 96.7425 130.739C94.9086 132.252 92.7938 133.173 90.3981 133.503C88.0024 133.832 85.3828 133.43 82.5393 132.297L81.4544 139.764L74.3453 134.156L75.2132 128.182L71.6586 125.378L70.7907 131.352L63.6815 125.743Z"
      stroke="black"
    />
  </svg>
);

export const Coin2 = ({ className, mainColor = '#BAFF62', sideColor = '#95CC4E' }) => (
  <svg
    className={className}
    width="106"
    height="106"
    viewBox="0 0 106 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="55.593"
      cy="55.4319"
      rx="42.8139"
      ry="28.1556"
      transform="rotate(-44.0834 55.593 55.4319)"
      fill={sideColor}
      stroke="black"
    />
    <ellipse
      cx="50.3424"
      cy="50.0108"
      rx="42.8139"
      ry="28.1556"
      transform="rotate(-44.0834 50.3424 50.0108)"
      fill={mainColor}
      stroke="black"
    />
    <path
      d="M63.14 31.6382C64.8022 31.926 66.163 32.4134 67.2225 33.1004C68.3606 33.7432 69.1718 34.5403 69.6561 35.4917C70.1405 36.4431 70.3244 37.504 70.208 38.6745C70.1181 39.8001 69.7023 40.99 68.9606 42.244L68.2453 43.4532L62.3137 43.3912L62.7905 42.585C63.6647 41.1071 63.8341 39.8471 63.2987 38.805C62.8153 37.7635 61.7822 37.0317 60.1996 36.6095L54.5174 46.2163C58.5711 46.7093 61.2398 47.7737 62.5235 49.4094C63.8858 51.0009 63.7457 53.185 62.1033 55.9618L61.8648 56.3649C60.09 59.3656 57.4368 61.7713 53.9052 63.582C50.4521 65.3484 46.6561 66.3227 42.5172 66.5047L40.0934 70.6027L34.4739 70.544L36.9773 66.3116C34.8733 65.9742 33.214 65.416 31.9993 64.6373C30.8632 63.8142 30.0666 62.8596 29.6097 61.7733C29.2314 60.6427 29.1529 59.4477 29.3743 58.1882C29.6222 56.884 30.1302 55.5824 30.8985 54.2836L31.8521 52.6713L37.7837 52.7333L37.3069 53.5395C36.0354 55.6892 35.6399 57.4201 36.1204 58.7319C36.601 60.0438 37.8535 60.9356 39.878 61.4074L46.3549 50.4571C42.7174 49.9684 40.2951 48.9742 39.088 47.4745C37.9595 45.9305 38.1899 43.8149 39.7793 41.1277L40.0177 40.7246C40.8654 39.2915 41.9193 38.0407 43.1795 36.9723C44.5181 35.8598 45.959 34.9285 47.502 34.1785C49.0715 33.3838 50.7171 32.7701 52.4389 32.3375C54.1872 31.86 55.9076 31.5626 57.6 31.4451L60.0636 27.2799L65.6831 27.3387L63.14 31.6382ZM55.7771 56.3012C56.7043 54.7337 56.8207 53.5632 56.1264 52.7899C55.432 52.0166 53.942 51.4377 51.6564 51.0533L45.3782 61.6677C47.748 61.4672 49.8354 60.9032 51.6405 59.9757C53.4976 59.0488 54.8765 57.824 55.7771 56.3012ZM46.1054 40.7883C45.3637 42.0423 45.1707 43.0768 45.5264 43.8916C45.9606 44.6623 47.1904 45.2384 49.2158 45.6201L54.7391 36.2821C52.9416 36.4886 51.2454 36.9666 49.6503 37.716C48.0553 38.4654 46.8737 39.4895 46.1054 40.7883Z"
      stroke="black"
    />
  </svg>
);

export const Coin3 = ({ className, mainColor = '#BAFF62', sideColor = '#95CC4E' }) => (
  <svg
    className={className}
    width="85"
    height="130"
    viewBox="0 0 85 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="20.9276"
      cy="68.2625"
      rx="42.8139"
      ry="28.1556"
      transform="rotate(-28.5975 20.9276 68.2625)"
      fill={sideColor}
      stroke="black"
    />
    <ellipse
      cx="17.3151"
      cy="61.6362"
      rx="42.8139"
      ry="28.1556"
      transform="rotate(-28.5975 17.3151 61.6362)"
      fill={mainColor}
      stroke="black"
    />
    <path
      d="M34.5535 47.3477C36.0785 48.0688 37.2598 48.9019 38.0974 49.8469C39.0226 50.7702 39.5915 51.7549 39.8042 52.8011C40.0169 53.8473 39.911 54.9188 39.4863 56.0157C39.0991 57.0765 38.3807 58.1121 37.331 59.1226L36.3189 60.0969L30.6192 58.4534L31.2939 57.8038C32.531 56.6129 33.0307 55.4439 32.7929 54.2967C32.6052 53.1639 31.805 52.1829 30.3925 51.3535L22.3516 59.0943C26.1265 60.6518 28.4141 62.3901 29.2145 64.3092C30.1024 66.2066 29.3842 68.274 27.06 70.5115L26.7226 70.8363C24.211 73.2542 21.0118 74.8641 17.125 75.6661C13.3256 76.4465 9.40731 76.3718 5.37007 75.4422L1.94003 78.7442L-3.45971 77.1872L0.0827981 73.7769C-1.85469 72.8899 -3.30475 71.909 -4.2674 70.8342C-5.14256 69.7377 -5.65527 68.605 -5.80553 67.4362C-5.86831 66.2456 -5.62488 65.0731 -5.07522 63.9184C-4.48808 62.7277 -3.65097 61.6091 -2.56385 60.5625L-1.21432 59.2634L4.48539 60.9069L3.81063 61.5565C2.01126 63.2887 1.168 64.8511 1.28082 66.2436C1.39365 67.6362 2.36257 68.83 4.1876 69.8253L13.3531 61.0018C9.97817 59.5596 7.90928 57.9548 7.14644 56.1872C6.47109 54.398 7.25801 52.4207 9.50722 50.2554L9.8446 49.9306C11.0442 48.7758 12.3938 47.8519 13.8934 47.1587C15.4806 46.444 17.1178 45.9312 18.805 45.6205C20.5297 45.2736 22.2794 45.1216 24.0542 45.1644C25.8665 45.1711 27.6039 45.3438 29.2662 45.6824L32.7525 42.3263L38.1523 43.8833L34.5535 47.3477ZM20.8729 69.1495C22.185 67.8864 22.6097 66.7896 22.147 65.8589C21.6843 64.9282 20.403 63.9725 18.303 62.9918L9.41864 71.5447C11.756 71.9842 13.9182 71.998 15.9054 71.5861C17.9425 71.1887 19.5984 70.3765 20.8729 69.1495ZM15.6943 51.6174C14.6447 52.6279 14.1825 53.5732 14.3077 54.4535C14.5203 55.312 15.5517 56.1956 17.4017 57.1043L25.2177 49.5799C23.4303 49.299 21.668 49.3068 19.9308 49.6031C18.1936 49.8994 16.7814 50.5708 15.6943 51.6174Z"
      stroke="black"
    />
  </svg>
);

export const Coin4 = ({ className, mainColor = '#BAFF62', sideColor = '#95CC4E' }) => (
  <svg
    className={className}
    width="135"
    height="211"
    viewBox="0 0 135 211"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="95.7583"
      cy="109.36"
      rx="70.3481"
      ry="46.2628"
      transform="rotate(45.5479 95.7583 109.36)"
      fill={sideColor}
      stroke="black"
    />
    <ellipse
      cx="104.61"
      cy="100.675"
      rx="70.3481"
      ry="46.2628"
      transform="rotate(45.5479 104.61 100.675)"
      fill={mainColor}
      stroke="black"
    />
    <path
      d="M101.776 104.713L103.354 93.2791L110.644 98.9469L109.065 110.381L121.215 119.827L125.46 89.0827L132.749 94.7505L126.084 143.027L118.794 137.359L119.847 129.736L107.697 120.29L106.645 127.913L99.3551 122.245L100.408 114.622L92.9153 108.797C87.313 104.441 83.2842 99.2455 80.8288 93.2098C78.3059 87.1216 77.5005 80.7744 78.4126 74.1682C79.2896 67.8161 81.5099 63.9151 85.0735 62.4654C88.5813 60.8786 93.1364 62.263 98.7387 66.6188L101.169 68.5081L99.8004 78.4174L97.3705 76.5282C93.9282 73.8517 91.2508 72.9424 89.3386 73.8003C87.3588 74.6057 86.1467 76.6176 85.7024 79.836C85.258 83.0544 85.7334 86.3783 87.1285 89.8077C88.4561 93.1846 90.8411 96.2112 94.2835 98.8877L101.776 104.713Z"
      stroke="black"
    />
  </svg>
);

export const Coin5 = ({ className, mainColor = '#BAFF62', sideColor = '#95CC4E' }) => (
  <svg
    className={className}
    width="150"
    height="171"
    viewBox="0 0 150 171"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="69.1356"
      cy="87.4384"
      rx="67.3946"
      ry="44.3205"
      transform="rotate(68.2456 69.1356 87.4384)"
      fill={sideColor}
      stroke="black"
    />
    <ellipse
      cx="80.1693"
      cy="83.0351"
      rx="67.3946"
      ry="44.3205"
      transform="rotate(68.2456 80.1693 83.0351)"
      fill={mainColor}
      stroke="black"
    />
    <path
      d="M76.1719 85.556L81.7938 76.0341L86.1415 83.7382L80.5195 93.2601L87.7655 106.1L102.882 80.4969L107.23 88.201L83.4928 128.405L79.1452 120.701L82.8932 114.353L75.6471 101.512L71.8992 107.86L67.5516 100.156L71.2995 93.8084L66.8311 85.8903C63.4899 79.9695 61.8499 73.8881 61.9109 67.6459C61.9318 61.3325 63.5663 55.4249 66.8145 49.9234C69.9378 44.6334 73.3422 42.0065 77.0277 42.0426C80.7146 41.9368 84.2287 44.8442 87.5699 50.765L89.0191 53.333L84.1467 61.5853L82.6975 59.0173C80.6445 55.3792 78.6143 53.5859 76.6071 53.6372C74.5596 53.6171 72.7446 54.9472 71.1622 57.6275C69.5797 60.3077 68.7711 63.4212 68.7364 66.9679C68.6614 70.4432 69.6505 73.9999 71.7035 77.6379L76.1719 85.556Z"
      stroke="black"
    />
  </svg>
);

export const Coin6 = ({ className, mainColor = '#BAFF62', sideColor = '#95CC4E' }) => (
  <svg
    className={className}
    width="126"
    height="116"
    viewBox="0 0 126 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="64.9888"
      cy="61.2108"
      rx="46.1622"
      ry="30.3575"
      transform="rotate(-32.204 64.9888 61.2108)"
      fill={sideColor}
      stroke="black"
    />
    <ellipse
      cx="60.6519"
      cy="54.3255"
      rx="46.1622"
      ry="30.3575"
      transform="rotate(-32.204 60.6519 54.3255)"
      fill={mainColor}
      stroke="black"
    />
    <path
      d="M40.3209 44.6007L45.3414 45.1393C47.367 43.3532 49.5785 41.8068 51.9758 40.5004C54.3731 39.1939 56.8281 38.1379 59.3409 37.3324C61.9426 36.4876 64.5018 35.9071 67.0185 35.5907C69.591 35.2802 72.0487 35.2507 74.3916 35.502C77.0135 35.7833 79.2085 36.3608 80.9768 37.2346C82.7451 38.1084 84.0359 39.2241 84.8494 40.5818C85.7518 41.9002 86.1541 43.4093 86.0562 45.1091C86.0142 46.8148 85.5103 48.6176 84.5446 50.5174L77.1248 51.0408C77.8175 49.8446 78.226 48.6669 78.3505 47.5075C78.5638 46.3088 78.4103 45.2417 77.8901 44.3064C77.4586 43.3317 76.6439 42.5113 75.4458 41.8453C74.2477 41.1793 72.6167 40.7355 70.5527 40.5141C67.6519 40.203 64.5835 40.5335 61.3475 41.5057C58.1672 42.4838 55.2306 43.9524 52.5376 45.9112L64.5869 47.2038L61.0145 52.0979L47.459 50.6438L45.6728 53.0908L59.2283 54.5449L55.6559 59.439L43.5229 58.1375C43.2286 60.3537 43.8115 62.1509 45.2717 63.5291C46.7877 64.9133 49.0519 65.767 52.0642 66.0902C54.1282 66.3116 56.0952 66.2538 57.9652 65.9169C59.9241 65.5407 61.7312 65.0016 63.3865 64.2996C65.1306 63.5583 66.7065 62.6767 68.1139 61.655C69.5772 60.6392 70.8504 59.5542 71.9336 58.3999L77.5672 60.3236C75.7647 62.1336 73.7268 63.7719 71.4534 65.2382C69.2358 66.7106 66.8602 67.9461 64.3267 68.9448C61.8262 69.8982 59.2288 70.5724 56.5344 70.9674C53.8399 71.3625 51.126 71.4134 48.3926 71.1202C46.0497 70.8689 43.9723 70.3773 42.1606 69.6454C40.4047 68.9196 38.9536 67.9821 37.8074 66.833C36.75 65.6445 36.0811 64.2535 35.8008 62.6598C35.5205 61.066 35.7237 59.3043 36.4105 57.3745L31.3899 56.836L34.9623 51.9419L38.9788 52.3727L40.7649 49.9257L36.7485 49.4948L40.3209 44.6007Z"
      stroke="black"
    />
  </svg>
);
