const icons = {
  'arrow-right':
    'M32 16L29.5518 18.4889L17.311 6.04443L19.7592 3.55554L32 16Z M29.5519 13.5111L32 16L19.7592 28.4444L17.3111 25.9555L29.5519 13.5111Z M2.17301e-07 17.6348L0 14.115L29.4289 14.115V17.6348L2.17301e-07 17.6348Z',
  cross:
    'M28.8 2.772e-06L32 3.2L3.2 32L0 28.8L28.8 2.772e-06Z M32 28.8L28.8 32L3.67482e-06 3.2L3.2 0L32 28.8Z',
  github:
    'M15.999 0c-8.839 0-15.999 7.341-15.999 16.404 0 7.26 4.58 13.39 10.94 15.565 0.8 0.143 1.1-0.349 1.1-0.779 0-0.39-0.021-1.681-0.021-3.055-4.019 0.759-5.059-1.005-5.38-1.927-0.18-0.471-0.96-1.927-1.639-2.317-0.561-0.308-1.36-1.067-0.021-1.087 1.26-0.020 2.16 1.189 2.461 1.681 1.44 2.482 3.74 1.785 4.659 1.354 0.14-1.067 0.56-1.785 1.020-2.195-3.559-0.41-7.279-1.825-7.279-8.1 0-1.784 0.62-3.261 1.639-4.409-0.16-0.41-0.72-2.091 0.16-4.346 0 0 1.341-0.431 4.401 1.68 1.301-0.37 2.648-0.556 4-0.553 1.36 0 2.72 0.185 4 0.553 3.059-2.133 4.399-1.681 4.399-1.681 0.88 2.255 0.32 3.937 0.16 4.347 1.021 1.148 1.64 2.604 1.64 4.409 0 6.294-3.74 7.689-7.3 8.099 0.58 0.514 1.080 1.497 1.080 3.035 0 2.195-0.020 3.957-0.020 4.512 0 0.43 0.3 0.943 1.1 0.779 6.513-2.256 10.898-8.518 10.899-15.566 0-9.063-7.16-16.404-15.999-16.404z',
  twitter:
    'M26.169 10.782c0.016 0.227 0.016 0.455 0.016 0.682 0 6.936-5.28 14.928-14.928 14.928-0.011 0-0.025 0-0.039 0-2.979 0-5.752-0.878-8.076-2.39l0.057 0.035c0.356 0.041 0.769 0.065 1.187 0.065 0.028 0 0.056-0 0.085-0l-0.004 0c0.008 0 0.018 0 0.028 0 2.457 0 4.718-0.844 6.507-2.257l-0.022 0.017c-2.286-0.043-4.212-1.539-4.895-3.601l-0.011-0.037c0.297 0.049 0.64 0.078 0.989 0.080h0.002c0.491-0.001 0.967-0.066 1.42-0.187l-0.039 0.009c-2.417-0.502-4.207-2.613-4.207-5.143 0-0.003 0-0.005 0-0.008v0-0.065c0.683 0.388 1.495 0.631 2.361 0.665l0.010 0c-1.418-0.956-2.339-2.556-2.339-4.371 0-0.975 0.266-1.888 0.728-2.671l-0.013 0.024c2.6 3.182 6.445 5.262 10.782 5.489l0.037 0.002c-0.080-0.36-0.127-0.774-0.13-1.198v-0.002c0.004-2.898 2.354-5.246 5.252-5.246 1.508 0 2.868 0.636 3.826 1.654l0.003 0.003c1.255-0.247 2.371-0.687 3.376-1.293l-0.046 0.026c-0.406 1.233-1.222 2.239-2.283 2.878l-0.024 0.013c1.12-0.131 2.141-0.418 3.088-0.84l-0.067 0.027c-0.732 1.057-1.603 1.954-2.6 2.691l-0.031 0.022z',
  discord:
    'M24.3468 8.52819C22.7822 7.81261 21.1206 7.29108 19.3862 7C19.1679 7.37599 18.9253 7.88538 18.7555 8.28562C16.9108 8.01879 15.0793 8.01879 13.2601 8.28562C13.0903 7.88538 12.8355 7.37599 12.6293 7C10.8829 7.29108 9.22126 7.81261 7.66761 8.52819C4.52633 13.1613 3.67733 17.6852 4.10182 22.1485C6.18793 23.6645 8.20128 24.5863 10.1794 25.1928C10.6646 24.5378 11.1012 23.8343 11.4772 23.0945C10.7616 22.8277 10.0824 22.5002 9.42747 22.1121C9.59727 21.9909 9.76708 21.8574 9.92475 21.724C13.8786 23.5312 18.1612 23.5312 22.0666 21.724C22.2364 21.8574 22.3941 21.9909 22.5639 22.1121C21.9089 22.5002 21.2297 22.8277 20.5141 23.0945C20.8901 23.8343 21.3268 24.5378 21.8119 25.1928C23.7888 24.5863 25.8143 23.6645 27.8895 22.1485C28.4109 16.9818 27.0635 12.4942 24.3468 8.52819ZM12.023 19.3953C10.8344 19.3953 9.86408 18.3159 9.86408 16.9939C9.86408 15.6719 10.8101 14.5924 12.023 14.5924C13.2237 14.5924 14.2061 15.6719 14.1818 16.9939C14.1818 18.3159 13.2237 19.3953 12.023 19.3953ZM19.9926 19.3953C18.804 19.3953 17.8325 18.3159 17.8325 16.9939C17.8325 15.6719 18.7797 14.5924 19.9926 14.5924C21.1934 14.5924 22.1757 15.6719 22.1515 16.9939C22.1515 18.3159 21.2055 19.3953 19.9926 19.3953Z',
};

export default icons;
